import projModal from "./projModal.html";

angular
  .module("kitstajm")
  .controller(
    "TimeCtrl",
    function (
      $scope,
      Modal,
      timeTravelMoment,
      ProjectsActivities,
      Timerecords,
      TimerecordsWeek,
      Slack,
      Trello,
      moment
    ) {
      moment.locale("sv");

      //$scope.showCopy = false;
      //$scope.chrome = (navigator.userAgent.match(/(Chrome)/g) ? true : false);
      //$scope.ios = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
      //$scope.useArrow = $scope.ios || !$scope.chrome || true;

      $scope.message = "Tidrapportering";
      $scope.datePickerDate = {};

      $scope.momentDate = moment(
        moment().startOf("day").isoWeekday(1).format("YYYY-MM-DD")
      );
      $scope.periodLength = 7;
      $scope.weekList = timeTravelMoment.getWeekList(
        $scope.momentDate,
        $scope.periodLength
      );

      $scope.isoWeeks = $scope.momentDate.isoWeeksInYear();

      $scope.timeReportYear = $scope.momentDate.format("YYYY");
      $scope.timeReportWeek = $scope.momentDate.isoWeek();

      $scope.lastWeek = $scope.timeReportWeek;
      $scope.lastYear = $scope.timeReportYear;

      $scope.weekNumberDate3 = { from: new Date($scope.momentDate) };

      $scope.projects = [];

      $scope.koko = 1;
      $scope.workHours = 0;
      $scope.weekHeaderZ = {};

      $scope.opened1 = false;

      //Datepicker
      $scope.dateOptions = {
        "year-format": "'yy'",
        "show-weeks": true,
        "starting-day": 1,
      };

      $scope.$watch(
        "weekNumberDate3.from",
        function (newVal, oldVal) {
          //console.log('changed', newVal, oldVal);
          $scope.updateWeekNo4();
        },
        true
      );

      $scope.daFormat = "yyyy Vww";

      $scope.weekHeader = function () {
        var header = [{ header: "Projekt" }, { header: "Aktivitet" }];
        //console.log($scope.koko++);
        return header.concat($scope.weekList);
      };

      $scope.slackPost = function (name) {
        var payload = {
          text: "hej hje!",
          channel: "@" + name,
        };
        Slack.send(
          {},
          payload,
          function (result) {},
          function (error) {}
        );
      };

      $scope.trelloList = function (data) {
        Trello.get(
          {},
          {},
          function (result) {
            //                console.log('result');
            //                console.log(result);
            var koko = result;
            //                for (var x=0;x<result.length;x++) {
            //                    var kaka = result[x];
            //                    if (koko[kaka.idList] === undefined) {
            //                        koko[kaka.idList] = [];
            //                    }
            //                    koko[kaka.idList].push(result[x].name);
            //                }
            $scope.cards = koko;
          },
          function (error) {
            //                console.log('error');
            //                console.log(error);
          }
        );
      };

      $scope.workHoursZ = function (weeklist) {
        var workHours = 0;
        var weekend = [6, 7];
        for (var i = 2; i < weeklist.length; i++) {
          if (weeklist[i].redDay !== undefined) {
            continue;
          }
          if (
            typeof weeklist[i].header === "object" &&
            weekend.indexOf(weeklist[i].header.isoWeekday()) !== -1
          ) {
            continue;
          }
          workHours += 8;
        }
        return workHours;
      };

      $scope.updateActivity = function (proj) {
        var row = $scope.currTimeTable[proj].data;
        var len = row.length;
        var data = {};
        for (var i = 0; i < len; i++) {
          if (row[i] === null) {
            continue;
          }
          if (row[i].id !== null) {
            data = {
              activity_id: $scope.currTimeTable[proj].header[1].id,
              time: row[i].time,
            };
            Timerecords.update(
              { id: row[i].id },
              data,
              function (result) {
                //console.log('update', result);
              },
              function (error) {
                //console.log('err update', error);
              }
            );
          } else {
            //console.log('null row[i].id', i, row[i]);
          }
        }
      };

      $scope.save = function (proj, day) {
        //localStorage.setItem('TR_CACHE', JSON.stringify($scope.TRcache));
        //console.log(proj, day);
        //console.log($scope.weekList[day]);
        //console.log(proj, $scope.currTimeTable[proj]);
        //console.log(day, $scope.currTimeTable[proj].data);

        if (day < 0) {
          return;
        }

        var time = day < 0 ? null : $scope.currTimeTable[proj].data[day].time;
        var recordId = day < 0 ? null : $scope.currTimeTable[proj].data[day].id;
        var project = {};
        var activity_id = {};

        if ($scope.currTimeTable[proj].header[0] !== null) {
          //console.log($scope.currTimeTable[proj]);
          project = $scope.currTimeTable[proj].header[0].id;
          activity_id = $scope.currTimeTable[proj].header[1].id;
        } else {
          //console.log('time ', time);
          if (time !== null && time >= 0 && time < 25) {
            $scope.openProjectActivityModal(proj, 0, day);
          }
          return;
        }

        var data = {
          date: $scope.weekList[day].header.format("YYYY-MM-DD"),
          time: time,
          project: project,
          activity_id: activity_id,
          id: recordId,
        };

        //console.log('now it time to insert or delete', proj, day, data);

        if (!time) {
          if (recordId) {
            Timerecords.delete(
              { id: recordId },
              data,
              function (result) {
                $scope.currTimeTable[proj].data[day].invalid = false;
                $scope.currTimeTable[proj].data[day].id = undefined;
                $scope.reportStatisticsZ = $scope.reportStatistics();
                $scope.updateStats();

                //console.log('del',result);
              },
              function (error) {
                $scope.currTimeTable[proj].data[day].invalid = true;
              }
            );
          } else {
            //no time and no recordId?
            //console.log('no time and no recordId', data);
          }
        } else {
          if (
            activity_id &&
            (data.id === null || typeof data.id === "undefined")
          ) {
            Timerecords.insert(
              {},
              data,
              function (result) {
                $scope.currTimeTable[proj].data[day].invalid = false;
                $scope.reportStatisticsZ = $scope.reportStatistics();
                $scope.updateStats();
                //console.log('ins',result);
                if (result.id !== undefined) {
                  //console.log('result.id found');
                  $scope.currTimeTable[proj].data[day].id = result.id;
                } else {
                  //console.log('result.id === undefined');
                }
              },
              function (error) {
                $scope.currTimeTable[proj].data[day].time = null;
                $scope.currTimeTable[proj].data[day].invalid = true;
              }
            );
          } else {
            if (activity_id && data.id) {
              Timerecords.update(
                { id: data.id },
                data,
                function (result) {
                  $scope.currTimeTable[proj].data[day].invalid = false;
                  $scope.reportStatisticsZ = $scope.reportStatistics();
                  $scope.updateStats();
                  //console.log('update ',result);
                },
                function (error) {
                  $scope.currTimeTable[proj].data[day].time = null;
                  $scope.currTimeTable[proj].data[day].invalid = true;
                }
              );
            } else {
              //console.log('no activity_id or data.id');
            }
          }
        }
      };

      $scope.load = function () {
        //$scope.TRcache = JSON.parse(localStorage.getItem('TR_CACHE'));
        if (!$scope.TRcache) {
          $scope.TRcache = [];
        }
        //console.log($scope.TRcache);
      };

      $scope.load();

      $scope.createTimeReportPeriodRow = function (periodLength) {
        var period = {};
        period.header = [null, null];
        period.data = [];
        period.statement = null;

        for (var i = 0; i < periodLength; i++) {
          period.data[i] = { time: null, id: null, statement: null };
        }
        return period;
      };

      $scope.createTimeReportWeek = function () {
        var TR = [];
        TR.push($scope.createTimeReportPeriodRow($scope.periodLength));
        return TR;
      };

      $scope.updateTimeReportCache = function () {
        if ($scope.TRcache[$scope.timeReportYear]) {
          if ($scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek]) {
            $scope.currTimeTable =
              $scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek];
          } else {
            $scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek] =
              $scope.createTimeReportWeek();
            $scope.currTimeTable =
              $scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek];
          }
        } else {
          $scope.TRcache[$scope.timeReportYear] = [];
          $scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek] =
            $scope.createTimeReportWeek();
          $scope.currTimeTable =
            $scope.TRcache[$scope.timeReportYear][$scope.timeReportWeek];
        }
        //console.log("updateTimeReportCache", $scope.currTimeTable);
        //console.log($scope.timeReportYear, $scope.timeReportWeek);
      };

      $scope.updateTimeReportCache();

      $scope.rowIsEmpty = function (row) {
        //console.log(row);
        var len = row.length;
        for (var i = 0; i < len; i++) {
          if (row[i] === null) {
            continue;
          }
          if (row[i].time !== null) {
            return false;
          }
        }
        return true;
      };

      $scope.roundInput = function (projIndex, index, currTimeTable) {
        if (currTimeTable[projIndex].data[index]) {
          var orgValue = currTimeTable[projIndex].data[index].time;
          var fraction = orgValue - Math.floor(orgValue);
          //console.log(fraction);
          if (fraction > 0 && fraction < 0.5) {
            orgValue = orgValue - fraction + 0.5;
            //console.log(orgValue);
          } else if (fraction > 0.5) {
            orgValue = orgValue - fraction + 1;
          }
          if (currTimeTable[projIndex].data[index].time !== orgValue) {
            currTimeTable[projIndex].data[index].time = orgValue;
            //console.log(orgValue + ' blur ' + $scope.currTimeTable[projIndex].data[index].time);
            $scope.save(projIndex, index);
          }
          //console.log(currTimeTable[projIndex].data[index].time);
        }
      };

      $scope.addRemoveSaveTimeReportRow = function (projIndex, index) {
        $scope.showCopy = false;
        //console.log('dra mig bak');
        $scope.save(projIndex, index);
        if (
          !$scope.currTimeTable[projIndex + 1] &&
          !$scope.rowIsEmpty($scope.currTimeTable[projIndex].data)
        ) {
          $scope.currTimeTable[projIndex + 1] =
            $scope.createTimeReportPeriodRow($scope.periodLength);
        } else {
          if (
            $scope.rowIsEmpty($scope.currTimeTable[projIndex].data) &&
            $scope.currTimeTable.length > 1 &&
            $scope.currTimeTable.length !== projIndex + 1
          ) {
            $scope.currTimeTable.splice(projIndex, 1);
          } else {
            //console.log('last or non empty row with index ' + projIndex);
          }
        }
        //console.log('change ' + $scope.currTimeTable[projIndex].data[index].time);
      };

      $scope.createPeriod = function (mom, periodLength, offset) {
        var period = {
          from: moment(moment(mom).startOf("day").format("YYYY-MM-DD"))
            .startOf("isoWeek")
            .subtract(offset, "days")
            .format("YYYY-MM-DD"),
          to: moment(moment(mom).startOf("day").format("YYYY-MM-DD"))
            .startOf("isoWeek")
            .add(periodLength, "days")
            .subtract(1 + offset, "days")
            .format("YYYY-MM-DD"),
        };
        return period;
      };

      $scope.foregoende = function () {
        var period = $scope.createPeriod(
          $scope.momentDate,
          $scope.periodLength * 4,
          $scope.periodLength * 4
        );
        $scope.getTimeReportData(period, false);
      };

      $scope.getTimeReportData = function (period, loadData) {
        var ko = TimerecordsWeek.get(
          { from: period.from, to: period.to },
          function (data) {
            //console.log(period);
            var row = 0;
            var len = data.length;
            if (len === 0 && loadData) {
              $scope.foregoende();
            }
            $scope.currTimeTable = [];
            $scope.currTimeTable[row] = $scope.createTimeReportPeriodRow(
              $scope.periodLength
            );

            for (var i = 0; i < len; i++) {
              if (!data[i].statement_id) {
                data[i].statement_id = data[i].salary_id;
              }
              //console.log(data[i]);
              if (i === 0) {
                $scope.currTimeTable[row].statement = data[i].statement_id;
                $scope.currTimeTable[row].salary = data[i].salary_id;
                $scope.currTimeTable[row].header[0] = {
                  id: data[i].project_id,
                };
                $scope.currTimeTable[row].header[1] = {
                  id: data[i].activity_id,
                };
                if (!data[i].endcustomer) {
                  $scope.currTimeTable[row].header[0].name =
                    data[i].customername + " " + data[i].project_name;
                } else {
                  $scope.currTimeTable[row].header[0].name =
                    data[i].endcustomer + " " + data[i].project_name;
                }
                $scope.currTimeTable[row].header[1].name =
                  data[i].activity_name;
                $scope.currTimeTable[row].header[1].start_date =
                  data[i].start_date;
                $scope.currTimeTable[row].header[1].stop_date =
                  data[i].stop_date;
              }

              if (
                $scope.currTimeTable[row].header[1].id !== data[i].activity_id
              ) {
                row++;
                $scope.currTimeTable[row] = $scope.createTimeReportPeriodRow(
                  $scope.periodLength
                );
                $scope.currTimeTable[row].statement = data[i].statement_id;
                $scope.currTimeTable[row].salary = data[i].salary_id;
                $scope.currTimeTable[row].header[0] = {
                  id: data[i].project_id,
                };
                $scope.currTimeTable[row].header[1] = {
                  id: data[i].activity_id,
                };
                if (!data[i].endcustomer) {
                  $scope.currTimeTable[row].header[0].name =
                    data[i].customername + " " + data[i].project_name;
                } else {
                  $scope.currTimeTable[row].header[0].name =
                    data[i].endcustomer + " " + data[i].project_name;
                }
                $scope.currTimeTable[row].header[1].name =
                  data[i].activity_name;
                $scope.currTimeTable[row].header[1].start_date =
                  data[i].start_date;
                $scope.currTimeTable[row].header[1].stop_date =
                  data[i].stop_date;
              }

              //console.log(data[i].date);

              if (loadData) {
                //console.log(data[i]);
                //console.log(moment(moment(new Date(data[i].reporteddate)).format('YYYY-MM-DD')));
                //console.log(moment(period.from));
                var nDays = moment(
                  moment(new Date(data[i].reporteddate)).format("YYYY-MM-DD")
                ).diff(moment(period.from), "days");
                $scope.currTimeTable[row].data[nDays] = {
                  time: data[i].time,
                  id: data[i].record_id,
                  statement: data[i].statement_id,
                  salary: data[i].salary_id,
                };
              }
            }

            if (len > 0) {
              $scope.currTimeTable[row + 1] = $scope.createTimeReportPeriodRow(
                $scope.periodLength
              );
            }
            if (loadData) {
              $scope.reportStatisticsZ = $scope.reportStatistics();
              $scope.updateStats();
              //console.log($scope.currTimeTable);
            }
          }
        );
        return ko;
      };

      $scope.outOfRange = function (a, b, c) {
        var dday = moment(moment(b).format("YYYY-MM-DD"));
        c.ddayy = dday;
        if (a) {
          var pStart = moment(a.start_date);
          c.pStartyy = pStart;
          if (pStart.isAfter(dday, "day")) {
            return true;
          }
          if (a.stop_date) {
            var pStop = moment(a.stop_date);
            c.pStopyy = pStop;
            if (pStop.isBefore(dday, "day")) {
              return true;
            }
          }
        }
        return false;
      };

      $scope.updateWeekNo3 = function (newDate) {
        //console.log(newDate.data);
        //console.log($scope.datePickerDate.data);
        $scope.weekNumberDate3.from = newDate.data;
        $scope.updateWeekNo();
      };

      $scope.updateWeekNo = function (dir) {
        $scope.isoWeeks = $scope.momentDate.isoWeeksInYear();

        //console.log(dir);

        if (dir > 0) {
          $scope.momentDate.add(1, "weeks");
          $scope.weekNumberDate3.from = new Date(
            $scope.momentDate.format("YYYY-MM-DD")
          );
        } else if (dir < 0) {
          $scope.momentDate.subtract(1, "weeks");
          $scope.weekNumberDate3.from = new Date(
            $scope.momentDate.format("YYYY-MM-DD")
          );
        }
      };

      $scope.updateWeekNo4 = function () {
        if (typeof $scope.weekNumberDate3.from === "undefined") {
          if ($scope.isoWeeks === 52) {
            if ($scope.momentDate.isoWeek() === 1) {
              $scope.momentDate.subtract(1, "weeks");
            } else if ($scope.momentDate.isoWeek() === 52) {
              $scope.momentDate.add(1, "weeks");
            }
          }
          $scope.weekNumberDate3.from = new Date(
            $scope.momentDate.format("YYYY-MM-DD")
          );
        } else if ($scope.isoWeeks === 53) {
          var newWeek = moment(
            moment($scope.weekNumberDate3.from)
              .startOf("day")
              .format("YYYY-MM-DD")
          );
          if (newWeek.isoWeek() === 53 && $scope.momentDate.isoWeek() === 1) {
            $scope.momentDate.subtract(1, "weeks");
            $scope.weekNumberDate3.from = new Date(
              $scope.momentDate.format("YYYY-MM-DD")
            );
          } else if (
            newWeek.isoWeek() === 1 &&
            $scope.momentDate.isoWeek() === 53
          ) {
            $scope.momentDate.add(1, "weeks");
            $scope.weekNumberDate3.from = new Date(
              $scope.momentDate.format("YYYY-MM-DD")
            );
          }
        }

        $scope.momentDate = moment(
          moment($scope.weekNumberDate3.from)
            .startOf("day")
            .format("YYYY-MM-DD")
        );
        $scope.isoWeeks = $scope.momentDate.isoWeeksInYear();
        $scope.timeReportYear = $scope.momentDate.format("YYYY");
        $scope.timeReportWeek = $scope.momentDate.isoWeek();
        $scope.workHours = 0;

        //console.log($scope.momentDate.format('YYYY-MM-DD'));
        //console.log($scope.weekNumberDate3.from);
        //console.log($scope.isoWeeks);
        //console.log($scope.timeReportYear);
        //console.log($scope.timeReportWeek);

        $scope.weekList = timeTravelMoment.getWeekList(
          $scope.momentDate,
          $scope.periodLength
        );
        $scope.weekListMoment = timeTravelMoment.getWeekList(
          $scope.momentDate,
          $scope.periodLength
        );
        $scope.weekHeaderZ = {};
        $scope.weekHeaderZ.days = $scope.weekHeader();
        $scope.weekHeaderZ.workHours = $scope.workHoursZ(
          $scope.weekHeaderZ.days
        );
        $scope.updateTimeReportCache();

        var period = $scope.createPeriod(
          $scope.momentDate,
          $scope.periodLength,
          0
        );
        $scope.getTimeReportData(period, true).$promise.then();

        ProjectsActivities.get(
          {
            from: period.from,
            to: period.to,
          },
          function (data) {
            //console.log(data);
            $scope.projects = data;
          }
        );
      };

      $scope.updateWeekNo();

      $scope.updateStats = function () {
        var workHours = 0;
        var period = {
          from: moment($scope.momentDate.format("YYYY-MM-DD")),
          to: moment($scope.momentDate.format("YYYY-MM-DD")),
        };

        period.from.startOf("month");
        period.to.startOf("month").add(1, "months").subtract(1, "days");
        //console.log('period1 before',period);
        //console.log('period from',period.from.format('YYYY-MM-DD'));
        //console.log('period to',period.to.format('YYYY-MM-DD'));
        var count = 0;
        for (
          var day = moment(period.from);
          day <= period.to;
          day.add(1, "days")
        ) {
          count++;
          //console.log('dagen ',day.format('YYYY-MM-DD'));
          if (timeTravelMoment.isRedDayMoment(day) !== undefined) {
            continue;
          }
          if (timeTravelMoment.isWeekendDay(day)) {
            //console.log('helg ',day.format('YYYY-MM-DD'));
            continue;
          }
          workHours += 8;
        }

        //console.log('count '+count);

        //console.log(workHours);

        TimerecordsWeek.get(
          {
            from: period.from.format("YYYY-MM-DD"),
            to: period.to.format("YYYY-MM-DD"),
          },
          function (data) {
            var row = 0;
            var len = data.length;
            var sum = 0;

            for (var i = 0; i < len; i++) {
              //console.log(data[i]);
              sum += data[i].time;
            }

            $scope.monthTotal = {};
            $scope.monthTotal.month = $scope.momentDate.format("MMMM");
            $scope.monthTotal.rapporterat = sum;
            $scope.monthTotal.total = workHours;
            $scope.monthTotal.fardig =
              ($scope.monthTotal.rapporterat / $scope.monthTotal.total) * 100;
            //console.log($scope.monthTotal);
          }
        );

        period.from.subtract(1, "months");
        period.to.startOf("month").subtract(1, "days");

        //console.log('period before',period);
        //console.log('period from',period.from.format('YYYY-MM-DD'));
        //console.log('period to',period.to.format('YYYY-MM-DD'));

        var lastWorkHours = 0;
        count = 0;
        for (
          var day2 = moment(period.from);
          day2 <= period.to;
          day2.add(1, "days")
        ) {
          count++;
          //console.log('dagen ',day);
          if (timeTravelMoment.isRedDayMoment(day2) !== undefined) {
            continue;
          }
          if (timeTravelMoment.isWeekendDay(day2)) {
            //console.log('helg ',day.format('YYYY-MM-DD'));
            continue;
          }
          lastWorkHours += 8;
        }
        //console.log(lastWorkHours);
        //console.log('count '+count);

        TimerecordsWeek.get(
          {
            from: period.from.format("YYYY-MM-DD"),
            to: period.to.format("YYYY-MM-DD"),
          },
          function (data) {
            var row = 0;
            var len = data.length;
            var sum = 0;

            for (var i = 0; i < len; i++) {
              //console.log(data[i]);
              sum += data[i].time;
            }
            $scope.lastMonthTotal = {};
            $scope.lastMonthTotal.month = period.from.format("MMMM");
            $scope.lastMonthTotal.rapporterat = sum;
            $scope.lastMonthTotal.total = lastWorkHours;
            $scope.lastMonthTotal.fardig =
              ($scope.lastMonthTotal.rapporterat /
                $scope.lastMonthTotal.total) *
              100;
            //console.log('last ', $scope.lastMonthTotal);
          }
        );
      };

      $scope.saveProjAct = function (proj, act) {
        $scope.currTimeTable[$scope.row].header[0] = proj;
        $scope.currTimeTable[$scope.row].header[1] = act;
      };

      $scope.checkProjAct = function (proj, act, dissa) {
        //console.log('nurå', proj, act);
        for (var i = 0; i < $scope.currTimeTable.length - 1; i++) {
          if (i === $scope.row) {
            continue;
          }
          if ($scope.currTimeTable[i].header[1].id === act.id) {
            //console.log('hittade act i listan', $scope.currTimeTable[i].header[1].name);
            dissa.state = true;
            return;
          }
        }
        dissa.state = false;
      };

      $scope.reportStatistics = function () {
        //console.log($scope.koko++);
        var res = { total: 0, subTotal: 0, text: "", fardig: 0 };
        try {
          for (var i = 0, n = $scope.currTimeTable.length; i < n; ++i) {
            res.text = res.text + $scope.currTimeTable[i].header[0].name;
            res.text = res.text + ", " + $scope.currTimeTable[i].header[1].name;
            for (
              var j = 0, m = $scope.currTimeTable[i].data.length;
              j < m;
              ++j
            ) {
              res.subTotal += $scope.currTimeTable[i].data[j].time
                ? $scope.currTimeTable[i].data[j].time
                : 0;
            }
            res.text = res.text + " Sum: " + res.subTotal + "\n";
            res.total += res.subTotal;
            res.subTotal = 0;
          }
        } catch (e) {
          //console.log($scope.currTimeTable);
        }
        res.text = res.text + " Totalsum: " + res.total + "\n";
        if (res.total >= $scope.weekHeaderZ.workHours) {
          res.total1 = $scope.weekHeaderZ.workHours;
          res.total2 = res.total - res.total1;
          res.fardig1 = Math.min(100 * (res.total1 / res.total), 100);
          res.fardig2 = Math.min(100 * (res.total2 / res.total), 100);
          res.fardig3 = 0;
        } else {
          res.total1 = res.total;
          res.total2 = $scope.weekHeaderZ.workHours - res.total;
          res.fardig1 = Math.min(
            100 * (res.total1 / $scope.weekHeaderZ.workHours),
            100
          );
          res.fardig2 = 0;
          res.fardig3 = Math.min(
            100 * (res.total2 / $scope.weekHeaderZ.workHours),
            100
          );
        }
        return res;
      };

      var findInArray = function (arr, id) {
        var len = arr.length;
        for (var i = 0; i < len; ++i) {
          if (i in arr) {
            if (arr[i].id === id) {
              return i;
            }
          }
        }
        return -1;
      };

      $scope.openProjectActivityModal = function (row, col, datacol) {
        $scope.row = row;
        $scope.col = col;
        var projIndex;
        var actIndex;
        var update = false;
        //console.log('kkk', row, col, datacol);
        if (col === 0 || col === 1) {
          var modalDefaults = {
            template: projModal,
          };

          if ($scope.currTimeTable[$scope.row].header[0] !== null) {
            update = true;
            projIndex = findInArray(
              $scope.projects,
              $scope.currTimeTable[$scope.row].header[0].id
            );
            actIndex = findInArray(
              $scope.projects[projIndex].activities,
              $scope.currTimeTable[$scope.row].header[1].id
            );
          }

          //console.log($scope.projects);
          var modalOptions = {
            closeButtonText: "Avbryt",
            actionButtonText: "OK",
            headerText: "Välj Projekt och Aktivitet",
            bodyText: "Perform this action?",
            projects: $scope.projects,
            //activities: $scope.activities,
            project: $scope.projects[projIndex],
            activity: $scope.projects[projIndex]
              ? $scope.projects[projIndex].activities[actIndex]
              : null,
            save: $scope.saveProjAct,
            kokoko: $scope.checkProjAct,
            dissa: { state: false },
          };
          var ress = Modal.showModal(modalDefaults, modalOptions);
          ress.then(
            function () {
              //console.log('bra', datacol);
              if (!update) {
                $scope.save(row, datacol);
              } else {
                //console.log('update ');
                $scope.updateActivity(row);
              }
            },
            function () {
              //console.log('dåligt', ko);
              if (datacol >= 0) {
                $scope.currTimeTable[$scope.row].data[datacol] = {
                  time: null,
                  id: null,
                };
                if (
                  $scope.rowIsEmpty($scope.currTimeTable[$scope.row].data) &&
                  $scope.currTimeTable.length > 1 &&
                  $scope.currTimeTable.length !== $scope.row + 1
                ) {
                  //console.log('ta bort rad');
                  $scope.currTimeTable.splice($scope.row, 1);
                }
              }
            }
          );
        }
      };
    }
  );
