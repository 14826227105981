angular
  .module("kitstajm")
  .controller(
    "LogoutCtrl",
    function ($scope, $location, Login, Auth2, $timeout, CONSTANTS) {
      $scope.message = CONSTANTS.byeMessage;
      Login.IsLoggedOut();

      Auth2.check({}, function () {
        Login.IsLoggedOut();
        $timeout(function () {
          $location.path("login");
        }, 4000);
      });
    }
  );
