/* global moment */

angular.module("kitstajm").factory("timeTravelMoment", function (moment) {
  var isWeekendDay = function (day) {
    var weekend = [6, 7];
    if (weekend.indexOf(day.isoWeekday()) !== -1) {
      //console.log('helg dag ',day);
      return true;
    }
    return false;
  };

  var getMidsummerDay = function (year) {
    var midsummer;
    for (var i = 19; i < 26; i++) {
      midsummer = moment({ y: year, M: 5, d: i });
      //console.log(midsummer);
      if (midsummer.isoWeekday() === 5) {
        return midsummer.date();
      }
    }
  };

  var isRedDay = function (yearDay, monthDay, dayDay) {
    var redDay = [
      { day: 1, month: 1, name: "Nyårsdagen" },
      { day: 6, month: 1, name: "Trettondag jul" },
      { day: 1, month: 5, name: "Första maj" },
      { day: 6, month: 6, name: "Sveriges nationaldag" },
      { day: 24, month: 12, name: "Julafton" },
      { day: 25, month: 12, name: "Juldagen" },
      { day: 26, month: 12, name: "Annandag jul" },
      { day: 31, month: 12, name: "Nyårsafton" },
    ];

    var easterDay = [
      { year: 2013, day: 31, month: 3 },
      { year: 2014, day: 20, month: 4 },
      { year: 2015, day: 5, month: 4 },
      { year: 2016, day: 27, month: 3 },
      { year: 2017, day: 16, month: 4 },
      { year: 2018, day: 1, month: 4 },
      { year: 2019, day: 21, month: 4 },
      { year: 2020, day: 12, month: 4 },
      { year: 2021, day: 4, month: 4 },
      { year: 2022, day: 17, month: 4 },
      { year: 2023, day: 9, month: 4 },
      { year: 2024, day: 31, month: 3 },
      { year: 2025, day: 20, month: 4 },
      { year: 2026, day: 5, month: 4 },
      { year: 2027, day: 28, month: 3 },
      { year: 2028, day: 16, month: 4 },
      { year: 2029, day: 1, month: 4 },
      { year: 2030, day: 21, month: 4 },
      { year: 2031, day: 13, month: 4 },
      { year: 2032, day: 28, month: 3 },
      { year: 2033, day: 17, month: 4 },
      { year: 2034, day: 9, month: 4 },
      { year: 2035, day: 25, month: 3 },
      { year: 2036, day: 13, month: 4 },
      { year: 2037, day: 5, month: 4 },
      { year: 2038, day: 25, month: 4 },
      { year: 2039, day: 10, month: 4 },
      { year: 2040, day: 1, month: 4 },
    ];

    //långfredag, annandag påsk, Kristi himmelsfärdsdag (sjätte torsdagen efter påskdagen),
    var afterEaster = [
      { days: -2, name: "Långfredag" },
      { days: 1, name: "Annandag påsk" },
      { days: 39, name: "Kristi himmelsfärdsdag" },
    ];

    var year = [];
    var koko, kiki, emonth, eday;
    for (var i = 0; i < easterDay.length; i++) {
      year[easterDay[i].year] = [];
      year[easterDay[i].year][easterDay[i].month] = [];
      //year[easterDay[i].year][easterDay[i].month][easterDay[i].day] = 'red';
      koko = moment({
        y: easterDay[i].year,
        M: easterDay[i].month - 1,
        d: easterDay[i].day,
      });
      for (var k = 0; k < afterEaster.length; k++) {
        kiki = moment(koko).add(afterEaster[k].days, "days");
        emonth = kiki.month() + 1;
        eday = kiki.date();
        //console.log(emonth, eday, easterDay[i].year, kiki);
        if (year[easterDay[i].year][emonth] === undefined) {
          year[easterDay[i].year][emonth] = [];
        }
        year[easterDay[i].year][emonth][eday] = afterEaster[k].name;
      }

      for (var j = 0; j < redDay.length; j++) {
        if (year[easterDay[i].year][redDay[j].month] === undefined) {
          year[easterDay[i].year][redDay[j].month] = [];
        }
        year[easterDay[i].year][redDay[j].month][redDay[j].day] =
          redDay[j].name;
      }
      if (year[easterDay[i].year][6] === undefined) {
        year[easterDay[i].year][6] = [];
      }
      year[easterDay[i].year][6][getMidsummerDay(easterDay[i].year)] =
        "Midsommarafton";
    }
    //console.log(year[2014]);
    //console.log('inne i isredday ',yearDay, monthDay, dayDay);
    try {
      return year[yearDay][monthDay][dayDay];
    } catch (e) {
      //console.log(e);
      //console.log(yearDay,monthDay,dayDay);
      return undefined;
    }
  };

  var isRedDayDate = function (moment) {
    //console.log('isRedDayDate ',date);
    //console.log('blir till ',date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    //console.log('blir till med+ ',date.getUTCFullYear(), date.getUTCMonth()+1, date.getUTCDate());
    try {
      return isRedDay(moment.year(), moment.month() + 1, moment.date());
    } catch (e) {
      //console.log('no');
      return undefined;
    }
  };

  var isRedDayMoment = function (moment) {
    //console.log('isRedDayDate ',date);
    //console.log('blir till ',date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    //console.log('blir till med+ ',date.getUTCFullYear(), date.getUTCMonth()+1, date.getUTCDate());
    try {
      return isRedDay(
        moment.format("YYYY"),
        moment.format("M"),
        moment.format("D")
      );
    } catch (e) {
      //console.log('no');
      return undefined;
    }
  };

  var getWeekList = function (date, periodLength) {
    var weekList = [];
    var start = moment(date).startOf("isoWeek").subtract(1, "days");

    for (var i = 0; i < periodLength; i++) {
      weekList[i] = { header: moment(start.add(1, "days")) };
      weekList[i].redDay = isRedDayDate(weekList[i].header);
      weekList[i].weekIndex = weekList[i].header.isoWeekday();
      //console.log(weekList[i]);
    }
    return weekList;
  };

  return {
    isRedDayDate: isRedDayDate,
    getWeekList: getWeekList,
    isRedDayMoment: isRedDayMoment,
    isWeekendDay: isWeekendDay,
  };
});
