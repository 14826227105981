angular
  .module("kitstajm")
  .factory("Employees", function ($resource) {
    return $resource(
      "api/employees/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );
  })
  .controller(
    "EmployeeCtrl",
    function ($scope, Employees, Activities, Customers, Projectss) {
      $scope.saveEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }

        if (typeof arr.editEmp.employee_id !== "undefined") {
          Employees.update(
            { id: arr.editEmp.employee_id },
            arr.editEmp,
            function (result) {
              // Save back to array obj
              $scope.empCopy($scope.employees.editEmp, $scope.employees.selEmp);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          Employees.insert(
            {},
            arr.editEmp,
            function (result) {
              // Save back to array obj
              arr.isNew = false;
              arr.editEmp.employee_id = result.insertId;
              var x = $scope.employees.data.push(arr.editEmp) - 1;
              arr.selEmp = arr.editEmp;
              arr.editEmp = {};
              $scope.empCopy($scope.employees.selEmp, $scope.employees.editEmp);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        }
      };

      $scope.moveEmp = function (dir, arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (dir >= 1 && arr.index < arr.data.length - 1) {
          arr.selEmp = arr.data[++arr.index];
          $scope.empCopy(arr.selEmp, arr.editEmp);
        } else if (dir < 1 && arr.index > 0) {
          arr.selEmp = arr.data[--arr.index];
          $scope.empCopy(arr.selEmp, arr.editEmp);
        }
      };

      $scope.cancelEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (arr.isNew) {
          arr.isNew = false;
          arr.selEmp = arr.prevSel;
        }

        delete arr.editEmp;
        arr.editEmp = {};
        $scope.empCopy(arr.selEmp, arr.editEmp);
      };

      $scope.addEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (!arr.isNew) {
          //$scope.projects.selProj = null;
          arr.prevSel = arr.selEmp;
          arr.editEmp = {};
          arr.selEmp = arr.editEmp;
          arr.isNew = true;
        }
      };
    }
  );
