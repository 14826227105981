angular
  .module("kitstajm")
  .factory("Activities", function ($resource) {
    return $resource(
      "api/activities/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
        delete: { method: "DELETE" },
      }
    );
  })
  .controller("ActivityCtrl", function ($scope, Activities, moment) {
    $scope.cancelAct = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (arr.isNew) {
        arr.isNew = false;
        arr.selAct = arr.prevSel;
      }
      delete arr.editAct;
      arr.editAct = {};
      $scope.actCopy(arr.selAct, arr.editAct);
    };

    $scope.moveAct = function (dir, arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (dir >= 1 && arr.actIndex < arr.activities.length - 1) {
        arr.selAct = arr.activities[++arr.actIndex];
        $scope.actCopy(arr.selAct, arr.editAct);
      } else if (dir < 1 && arr.actIndex > 0) {
        arr.selAct = arr.activities[--arr.actIndex];
        $scope.actCopy(arr.selAct, arr.editAct);
      }
    };

    $scope.saveAct = function (arr) {
      //console.log(arr);

      arr.editAct.mstart_date = moment(arr.editAct.start_date).format(
        "YYYY-MM-DD"
      );
      arr.editAct.mstop_date = moment(arr.editAct.stop_date).format(
        "YYYY-MM-DD"
      );

      if (typeof arr === "undefined") {
        return;
      }

      if (typeof arr.editAct.activity_id !== "undefined") {
        //console.log(arr);
        Activities.update(
          { id: arr.editAct.activity_id },
          arr.editAct,
          function (result) {
            // Save back to array obj
            $scope.actCopy(arr.editAct, arr.selAct);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      } else {
        Activities.insert(
          {},
          arr.editAct,
          function (result) {
            // Save back to array obj
            arr.isNew = false;
            // id from DB
            arr.editAct.activity_id = result.insertId;
            // add to all activities
            var x = $scope.activities.data.push(arr.editAct) - 1;
            $scope.activities.selAct = $scope.activities.data[x];
            // add employee info
            $scope.activities.selAct.employee = $scope.employees.selEmp;
            // add to selected proj activities list
            if (typeof arr.activities === "undefined") {
              //first activity
              arr.activities = [];
            }
            var y = arr.activities.push($scope.activities.data[x]) - 1;
            arr.actIndex = y;
            // add to employees activities
            $scope.employees.selEmp.activities.push($scope.activities.selAct);
            arr.selAct = arr.activities[arr.actIndex];
            // remove ref to new
            arr.editAct = {};
            // copy back data
            $scope.actCopy(arr.selAct, arr.editAct);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      }
    };

    $scope.addAct = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }

      if (!arr.isNew) {
        //arr.selAct = null;
        $scope.employees.selEmp = null;
        arr.prevSel = arr.selAct;
        arr.editAct = {};
        arr.selAct = arr.editAct;
        arr.editAct.project_id = arr.project_id;
        arr.isNew = true;
      }
    };

    $scope.delAct = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }

      if (typeof arr.editAct.activity_id !== "undefined") {
        Activities.delete(
          { id: arr.editAct.activity_id },
          arr.editAct,
          function (result) {
            //console.log(result);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      } else {
        //console.log('no id');
      }
    };
  });
