angular
  .module("kitstajm")
  .controller(
    "AdminCtrl",
    function (
      $scope,
      Activities,
      Customers,
      Projectss,
      Employees,
      CustProjects,
      moment
    ) {
      $scope.kurra = {};

      $scope.menu = [
        { title: "Anställda", active: true, route: "emp" },
        { title: "Kunder", active: false, route: "cust" },
        { title: "Projekt", active: false, route: "proj" },
        { title: "Aktiviteter", active: false, route: "act" },
      ];

      $scope.findCustById = function (id, array) {
        for (var i = 0, l = array.length; i < l; i++) {
          if (id === array[i].customer_id) {
            return array[i];
          }
        }
        return null;
      };

      $scope.findProjById = function (id, array) {
        for (var i = 0, l = array.length; i < l; i++) {
          if (id === array[i].project_id) {
            return array[i];
          }
        }
        return null;
      };

      $scope.findEmpById = function (id, array) {
        for (var i = 0, l = array.length; i < l; i++) {
          if (id === array[i].employee_id) {
            return array[i];
          }
        }
        return null;
      };

      $scope.selectOptions = {};
      $scope.fetchCustProjects = function () {
        CustProjects.get({}, function (data) {
          $scope.selectOptions.customers = data;
          //console.log($scope.selectOptions.customers);
        });
      };

      $scope.fetchCustProjects();

      $scope.koko = function (a, b, c) {
        //console.log(a, b);
        //console.log(c.customername, c.projects.length);
      };

      $scope.actCopy = function (src, dst) {
        var prop = [
          "activity_name",
          "activity_id",
          "currency_id",
          "employee_id",
          "input_date",
          "invoice_lvl",
          "price",
          "project_id",
          "start_date",
          "stop_date",
          "unit",
        ];
        prop.forEach(function (name) {
          dst[name] = src[name];
        });
      };

      $scope.empCopy = function (src, dst) {
        var prop = [
          "admin",
          "contractor",
          "employee_id",
          "loginname",
          "name",
          "passwd",
          "start_date",
          "stop_date",
        ];
        prop.forEach(function (name) {
          dst[name] = src[name];
        });
      };

      $scope.custCopy = function (src, dst) {
        var prop = [
          "addinfo",
          "address1",
          "address2",
          "city",
          "country",
          "customer_id",
          "customername",
          "inputdate",
          "invoice_lvl",
          "legalname",
          "vatno",
          "zipcode",
        ];
        prop.forEach(function (name) {
          dst[name] = src[name];
        });
      };

      $scope.projCopy = function (src, dst) {
        var prop = [
          "addinfo",
          "customer",
          "customer_id",
          "endcustomer",
          "extprojectid",
          "extreference",
          "inputdate",
          "invoice_lvl",
          "ourreference",
          "project_id",
          "project_name",
        ];
        prop.forEach(function (name) {
          dst[name] = src[name];
        });
      };

      var cust;
      Employees.get({}, function (data) {
        //console.log(data);
        $scope.kurra.enableSorting = true;
        $scope.kurra.columnDefs = [
          { name: "ID", field: "employee_id" },
          { name: "Name", field: "name" },
          { name: "loginname", field: "loginname" },
          { name: "admin", field: "admin" },
          { name: "contractor", field: "contractor" },
          {
            name: "Start",
            field: 'start_date.format("YYYY-MM-DD")',
          },
          { name: "Stop", field: "stop_date", type: "moment" },
        ];
        $scope.kurra.data = data;

        //console.log($scope.kurra);
        $scope.employees = {};
        $scope.employees.data = data;
        $scope.employees.index = 1;
        $scope.employees.selEmp = $scope.employees.data[$scope.employees.index];
        for (var h = 0, l = $scope.employees.data.length; h < l; h++) {
          $scope.employees.data[h].start_date = moment(
            new Date($scope.employees.data[h].start_date)
          );
          if ($scope.employees.data[h].stop_date !== null) {
            $scope.employees.data[h].stop_date = moment(
              new Date($scope.employees.data[h].stop_date)
            ).toDate();
          }
        }
        $scope.employees.editEmp = {};
        $scope.empCopy($scope.employees.selEmp, $scope.employees.editEmp);

        Customers.get({}, function (data) {
          $scope.customers = {};
          $scope.customers.data = data;
          $scope.customers.index = 0;
          $scope.customers.selCust =
            $scope.customers.data[$scope.customers.index];

          $scope.customers.editCust = {};
          $scope.custCopy($scope.customers.selCust, $scope.customers.editCust);

          Projectss.get({}, function (data) {
            $scope.projects = {};
            $scope.projects.data = data;
            $scope.projects.index = 0;
            $scope.projects.selProj =
              $scope.projects.data[$scope.projects.index];
            for (var i = 0, len = $scope.projects.data.length; i < len; i++) {
              cust = $scope.findCustById(
                $scope.projects.data[i].customer_id,
                $scope.customers.data
              );
              //console.log(cust) ;
              if (cust !== null) {
                $scope.projects.data[i].customer = cust;
                cust.projIndex = 0;
                if (typeof cust.projects === "undefined") {
                  cust.projects = [];
                  cust.selProj = $scope.projects.data[i];
                  cust.editProj = {};
                  $scope.projCopy(cust.selProj, cust.editProj);
                }
                cust.projects.push($scope.projects.data[i]);
              }
            }
            $scope.projects.editProj = {};
            $scope.projCopy($scope.projects.selProj, $scope.projects.editProj);

            Activities.get({}, function (data) {
              $scope.activities = {};
              $scope.activities.data = data;
              $scope.activities.index = 0;
              $scope.activities.selAct =
                $scope.activities.data[$scope.activities.index];
              var proj, emp;
              for (var j = 0, ll = $scope.activities.data.length; j < ll; j++) {
                $scope.activities.data[j].start_date = moment(
                  new Date($scope.activities.data[j].start_date)
                ).toDate();

                if ($scope.activities.data[j].stop_date !== null) {
                  $scope.activities.data[j].stop_date = moment(
                    new Date($scope.activities.data[j].stop_date)
                  ).toDate();
                }
                proj = $scope.findProjById(
                  $scope.activities.data[j].project_id,
                  $scope.projects.data
                );
                if (proj !== null) {
                  $scope.activities.data[j].project = proj;
                  proj.actIndex = 0;
                  if (typeof proj.activities === "undefined") {
                    proj.activities = [];
                    proj.editAct = {};
                    proj.selAct = $scope.activities.data[j];
                    $scope.actCopy(proj.selAct, proj.editAct);
                  }
                  proj.activities.push($scope.activities.data[j]);
                }

                emp = $scope.findEmpById(
                  $scope.activities.data[j].employee_id,
                  $scope.employees.data
                );
                if (emp !== null) {
                  $scope.activities.data[j].employee = emp;
                  emp.empIndex = 0;
                  if (typeof emp.activities === "undefined") {
                    emp.activities = [];
                    emp.editAct = {};
                    emp.selAct = $scope.activities.data[j];
                    $scope.actCopy(emp.selAct, emp.editAct);
                  }
                  emp.activities.push($scope.activities.data[j]);
                }
              }
              $scope.activities.editAct = {};
              $scope.actCopy(
                $scope.activities.selAct,
                $scope.activities.editAct
              );
            });
          });
        });
      });
    }
  );
