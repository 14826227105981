angular.module("kitstajm").factory("Login", function (Auth) {
  var user = { name: null, role: null };
  var loginState = { isLoggedIn: user.name !== null };

  var localIsLoggedIn = function (userName, userData) {
    loginState.isLoggedIn = true;
    user.name = userName;
    user.slack = userData.slack;
    user.role = userData.role;
    user.id = userData.id;
  };

  var lps = Auth.get(
    function (data) {
      //console.log("jådd   åå");
      localIsLoggedIn(data.username, data);
    },
    function (error) {}
  ).$promise;

  //console.log("iininininin");

  return {
    promise: lps,
    getLoginState: function () {
      return loginState;
    },
    IsLoggedIn: function (userName, userData) {
      localIsLoggedIn(userName, userData);
    },
    IsLoggedOut: function () {
      loginState.isLoggedIn = false;
      user.name = null;
      user.slack = null;
      user.role = null;
    },
    getUser: function () {
      return user;
    },
    isAdmin: function () {
      return user.role === "a" ? true : false;
    },
    isKc: function () {
      return user.role === "k" ? true : false;
    },
    isSales: function () {
      return user.role === "s" ? true : false;
    },
    isBoard: function () {
      return user.role === "b" ? true : false;
    },
  };
});
