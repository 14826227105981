angular
  .module("kitstajm")
  .controller(
    "LogincallbackCtrl",
    function ($scope, $location, Login, Auth, Slack) {
      $scope.errorMessage = "";

      $scope.callback = function () {
        Login.promise = Auth.get(
          {},
          function (success) {
            $scope.errorMessage = "";
            Login.IsLoggedIn(success.name, success);
            //$scope.slackPost(success.slack);
            $location.path("time");
          },
          function (error) {
            $location.path("login");
            $scope.errorMessage = error.statusText;
            //console.log(error.statusText);
          }
        ).$promise;
      };
      $scope.callback();
    }
  );
