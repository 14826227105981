/* global d3 */

angular
  .module("kitstajm")
  .controller(
    "TimeStatisticsReportCtrl",
    function ($scope, CustProjects, TimeStats, Result, moment) {
      $scope.formData = {};
      $scope.formData.dateTo = null;
      $scope.formData.dateFrom = null;
      $scope.opened1 = false;
      $scope.opened2 = false;

      //Datepicker
      $scope.dateOptions = {
        "year-format": "'yy'",
        "show-weeks": true,
        "starting-day": 1,
      };

      $scope.message = "Projektrapport";

      $scope.header = ["ID", "Namn", "Summa"];

      $scope.custMode = { external: true, internal: true, absense: true };
      $scope.groupMode = "K";
      $scope.timeMode = "A";
      $scope.sumMode = "A";

      if (!$scope.formData.dateTo) {
        $scope.momentStop = moment(
          moment().startOf("day").startOf("month").format("YYYY-MM-DD")
        )
          .add(1, "month")
          .subtract(1, "days");
        $scope.formData.dateTo = $scope.momentStop.toDate();
      }

      if (!$scope.formData.dateFrom) {
        $scope.momentStart = $scope.momentStop
          .add(1, "days")
          .subtract(12, "month");
        $scope.formData.dateFrom = $scope.momentStart.toDate();
      }

      $scope.selectOptions = {};
      $scope.fetchCustProjects = function () {
        CustProjects.get({}, function (data) {
          $scope.selectOptions.customers = data;
          //console.log($scope.selectOptions.customers);
        });
      };

      $scope.fetchCustProjects();

      $scope.getHeader = function () {
        return $scope.header;
      };

      $scope.koko = function () {
        //console.log($scope.invoice);
        return $scope.exportData;
      };

      $scope.updatePeriod = function (dir) {
        $scope.momentStart($scope.formData.dateFrom, "YYYY-MM-DD");
        $scope.momentStop($scope.formData.dateTo, "YYYY-MM-DD");
        if (dir === -1) {
          $scope.momentStart.startOf("month").subtract(1, "months");
          $scope.momentStop.startOf("month").subtract(1, "days");
        } else {
          $scope.momentStart.startOf("month").add(1, "months");
          $scope.momentStop
            .startOf("month")
            .add(2, "months")
            .subtract(1, "days");
        }
        $scope.formData.dateFrom = $scope.momentStart.toDate();
        $scope.formData.dateTo = $scope.momentStop.toDate();
      };

      $scope.kaka = function (head, inv, index, row) {
        if (!$scope.exportData[row]) {
          $scope.exportData[row] = [];
        }
        switch (head) {
          case "År":
            $scope.exportData[row][index] = inv.year;
            break;
          case "Månad":
            $scope.exportData[row][index] = inv.month;
            break;
          case "Totalt":
            $scope.exportData[row][index] = inv.tot;
            break;
          case "Debiterbart":
            $scope.exportData[row][index] = inv.debit;
            break;
          case "Idle":
            $scope.exportData[row][index] = inv.idle;
            break;
          case "Intern/kompetens":
            $scope.exportData[row][index] = inv.int_komp + inv.internt;
            break;
          case "Intern projekt":
            $scope.exportData[row][index] = inv.int_proj;
            break;
          case "Sjukdom":
            $scope.exportData[row][index] = inv.sjuk;
            break;
          case "OH":
            $scope.exportData[row][index] = inv.oh;
            break;
          case "Tjänstledigt":
            $scope.exportData[row][index] = inv.tjl;
            break;
          case "Semester":
            $scope.exportData[row][index] = inv.semester;
            break;
          case "Intäkt":
            $scope.exportData[row][index] = inv.intakt;
            break;
          case "Total":
            $scope.exportData[row][index] = inv.hours;
            break;
          case "Timpris":
            $scope.exportData[row][index] = inv.timpris;
            break;
        }
        return $scope.exportData[row][index];
      };

      $scope.fetchSummary = function () {
        $scope.exportData = [];
        $scope.invoice = [];

        switch ($scope.groupMode) {
          case "KU":
            $scope.header = [
              "År",
              "Månad",
              "Totalt",
              "Debiterbart",
              "Idle",
              "Intern/kompetens",
              "Intern projekt",
              "Sjukdom",
              "OH",
              "Tjänstledigt",
              "Semester",
              "Intäkt",
              "Total",
              "Timpris",
            ];
            break;
          case "A":
            $scope.header = [
              "År",
              "Månad",
              "Totalt",
              "Debiterbart",
              "Idle",
              "Intern/kompetens",
              "Intern projekt",
              "Sjukdom",
              "OH",
              "Tjänstledigt",
              "Semester",
              "Intäkt",
              "Total",
              "Timpris",
            ];
            break;

          case "P":
            $scope.header = [
              "År",
              "Månad",
              "Totalt",
              "Debiterbart",
              "Idle",
              "Intern/kompetens",
              "Intern projekt",
              "Sjukdom",
              "OH",
              "Tjänstledigt",
              "Semester",
              "Intäkt",
              "Total",
              "Timpris",
            ];
            break;

          case "K":
            $scope.header = [
              "År",
              "Månad",
              "Totalt",
              "Debiterbart",
              "Idle",
              "Intern/kompetens",
              "Intern projekt",
              "Sjukdom",
              "OH",
              "Tjänstledigt",
              "Semester",
              "Intäkt",
              "Total",
              "Timpris",
            ];
            break;

          case "T":
            $scope.header = [
              "År",
              "Månad",
              "Totalt",
              "Debiterbart",
              "Idle",
              "Intern/kompetens",
              "Intern projekt",
              "Sjukdom",
              "OH",
              "Tjänstledigt",
              "Semester",
              "Intäkt",
              "Total",
              "Timpris",
            ];
            break;

          default:
            break;
        }

        $scope.momentStart = moment($scope.formData.dateFrom);
        $scope.momentStop = moment($scope.formData.dateTo);

        var cust, proj, act;

        if (!$scope.selectOptions.customer) {
          cust = null;
        } else {
          cust = $scope.selectOptions.customer.id;
        }
        if (!$scope.selectOptions.project) {
          proj = null;
        } else {
          proj = $scope.selectOptions.project.id;
        }
        if (!$scope.selectOptions.activity_name) {
          act = null;
        } else {
          act = $scope.selectOptions.activity_name.id;
        }

        $scope.pdata = [
          {
            key: "Timpris",
            bar: true,
            values: [],
          },
          {
            key: "Timpris (utan 0kr)",
            bar: true,
            values: [],
          },
        ];

        $scope.bdata = [
          {
            key: "Tillgängliga timmar",
            bar: true,
            values: [],
          },
          {
            key: "Utan OH/Tjl",
            bar: true,
            values: [],
          },
        ];

        $scope.rdata = [
          {
            key: "Omsättning",
            bar: true,
            values: [],
          },
          {
            key: "Resultat",
            bar: true,
            values: [],
          },
          {
            key: "Prognos Oms",
            bar: true,
            values: [
              ["2016-05-01", 2000000],
              ["2016-06-01", 2100000],
            ],
          },
          {
            key: "Prognos Res",
            bar: true,
            values: [
              ["2016-05-01", 300000],
              ["2016-06-01", 340000],
            ],
          },
        ];

        $scope.tdata = [
          {
            key: "Debiterbart",
            bar: true,
            values: [],
          },
          {
            key: "Idle",
            bar: true,
            values: [],
          },
          {
            key: "Intern/kompetens",
            bar: true,
            values: [],
          },
          {
            key: "Intern projekt",
            bar: true,
            values: [],
          },
          {
            key: "Sjukdom",
            bar: true,
            values: [],
          },
          {
            key: "OH",
            bar: true,
            values: [],
          },
          {
            key: "Tjänstledigt",
            bar: true,
            values: [],
          },
          {
            key: "Semester",
            bar: true,
            values: [],
          },
        ];

        TimeStats.get(
          {
            custMode: $scope.custMode,
            cust: cust,
            proj: proj,
            act: act,
            per: $scope.timeMode,
            type: $scope.groupMode,
            from: $scope.momentStart.format("YYYY-MM-DD"),
            to: $scope.momentStop.format("YYYY-MM-DD"),
          },
          function (data) {
            //spinnerService.hide('timeStatisticsReportSpinner1');
            $scope.invoice = data;
            for (var j = 0; j < $scope.invoice.length; j++) {
              var datum = new Date(
                $scope.invoice[j].year,
                $scope.invoice[j].month - 1
              );
              //console.log(datum);
              $scope.pdata[0].values.push([datum, $scope.invoice[j].timpris]);
              $scope.pdata[1].values.push([
                datum,
                $scope.invoice[j].timpris_utan_noll,
              ]);

              $scope.bdata[0].values.push([
                datum,
                (100 * $scope.invoice[j].debit) / $scope.invoice[j].tot,
              ]);
              $scope.bdata[1].values.push([
                datum,
                (100 * $scope.invoice[j].debit) /
                  ($scope.invoice[j].tot -
                    $scope.invoice[j].oh -
                    $scope.invoice[j].tjl -
                    $scope.invoice[j].semester),
              ]);

              $scope.tdata[0].values.push([datum, $scope.invoice[j].debit]);
              $scope.tdata[1].values.push([datum, $scope.invoice[j].idle]);
              $scope.tdata[2].values.push([datum, $scope.invoice[j].int_komp]);
              $scope.tdata[3].values.push([datum, $scope.invoice[j].int_proj]);
              $scope.tdata[4].values.push([datum, $scope.invoice[j].sjuk]);
              $scope.tdata[5].values.push([datum, $scope.invoice[j].oh]);
              $scope.tdata[6].values.push([datum, $scope.invoice[j].tjl]);
              $scope.tdata[7].values.push([datum, $scope.invoice[j].semester]);
              //$scope.tdata[1].values.push([datum,$scope.invoice[j].timpris]);
              //$scope.tdata[3].values.push([datum,$scope.invoice[j].timpris]);
            }
            //console.log($scope.tdata);
          }
        );

        Result.get(
          {
            from: $scope.momentStart.format("YYYY-MM-DD"),
            to: $scope.momentStop.format("YYYY-MM-DD"),
          },
          function (data) {
            $scope.result = data;
            for (var j = 0; j < $scope.result.length; j++) {
              if ($scope.result[j].account_name === "res") {
                $scope.rdata[1].values.push([
                  $scope.result[j].period,
                  $scope.result[j].value,
                ]);
              }
              if ($scope.result[j].account_name === "oms") {
                $scope.rdata[0].values.push([
                  $scope.result[j].period,
                  $scope.result[j].value,
                ]);
              }
            }
          }
        );
      };

      $scope.coptions = {
        chart: {
          type: "multiBarChart",
          height: 450,
          margin: {
            top: 20,
            right: 20,
            bottom: 65,
            left: 50,
          },
          stacked: false,
          x: function (d) {
            return d[0];
          },
          y: function (d) {
            return d[1];
          },
          showValues: true,
          valueFormat: function (d) {
            return d3.format(",.1f")(d);
          },
          duration: 100,
          xAxis: {
            axisLabel: "Månad",
            tickFormat: function (d) {
              return d3.time.format("%Y-%m")(new Date(d));
            },
            rotateLabels: 30,
            showMaxMin: false,
          },
          yAxis: {
            axisLabel: "Timpris",
            axisLabelDistance: -10,
            tickFormat: function (d) {
              return d3.format(",.2f")(d);
            },
          },
          tooltip: {
            keyFormatter: function (d) {
              return d;
            },
          },
          zoom: {
            enabled: true,
            scaleExtent: [1, 10],
            useFixedDomain: false,
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: "dblclick.zoom",
          },
        },
      };

      $scope.roptions = {
        chart: {
          type: "multiBarChart",
          height: 450,
          margin: {
            top: 20,
            right: 20,
            bottom: 65,
            left: 50,
          },
          stacked: false,
          showControls: false,
          x: function (d) {
            return d[0];
          },
          y: function (d) {
            return d[1];
          },
          showValues: true,
          valueFormat: function (d) {
            return d3.format(",.1f")(d);
          },
          duration: 100,
          xAxis: {
            axisLabel: "Månad",
            tickFormat: function (d) {
              return d3.time.format("%Y-%m")(new Date(d));
            },
            rotateLabels: 30,
            showMaxMin: false,
          },
          yAxis: {
            axisLabel: "K SEK",
            axisLabelDistance: -10,
            showMaxMin: false,
            tickFormat: function (d) {
              return d3.format("3,f")(d / 1000);
            },
          },
          tooltip: {
            keyFormatter: function (d) {
              return d;
            },
          },
          zoom: {
            enabled: true,
            scaleExtent: [1, 10],
            useFixedDomain: false,
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: "dblclick.zoom",
          },
        },
      };

      $scope.doptions = {
        chart: {
          type: "multiBarChart",
          height: 450,
          margin: {
            top: 20,
            right: 20,
            bottom: 65,
            left: 50,
          },
          stacked: false,
          x: function (d) {
            return d[0];
          },
          y: function (d) {
            return d[1];
          },
          showValues: true,
          valueFormat: function (d) {
            return d3.format(",.1f")(d);
          },
          duration: 100,
          xAxis: {
            axisLabel: "Månad",
            tickFormat: function (d) {
              return d3.time.format("%Y-%m")(new Date(d));
            },
            rotateLabels: 30,
            showMaxMin: false,
          },
          yAxis: {
            axisLabel: "Timpris",
            axisLabelDistance: -10,
            tickFormat: function (d) {
              return d3.format(",.2f")(d);
            },
          },
          tooltip: {
            keyFormatter: function (d) {
              return d;
            },
          },
          zoom: {
            enabled: true,
            scaleExtent: [1, 10],
            useFixedDomain: false,
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: "dblclick.zoom",
          },
        },
      };

      $scope.cdata = [
        {
          key: "Quantity",
          bar: true,
          values: [
            [1136005200000, 1271000.0],
            [1138683600000, 1271000.0],
            [1141102800000, 1271000.0],
            [1143781200000, 0],
            [1146369600000, 0],
            [1149048000000, 0],
            [1151640000000, 0],
            [1154318400000, 0],
            [1156996800000, 0],
            [1159588800000, 3899486.0],
            [1162270800000, 3899486.0],
            [1164862800000, 3899486.0],
            [1167541200000, 3564700.0],
            [1170219600000, 3564700.0],
            [1172638800000, 3564700.0],
            [1175313600000, 2648493.0],
            [1177905600000, 2648493.0],
            [1180584000000, 2648493.0],
            [1183176000000, 2522993.0],
            [1185854400000, 2522993.0],
            [1188532800000, 2522993.0],
            [1191124800000, 2906501.0],
            [1193803200000, 2906501.0],
            [1196398800000, 2906501.0],
            [1199077200000, 2206761.0],
            [1201755600000, 2206761.0],
            [1204261200000, 2206761.0],
            [1206936000000, 2287726.0],
            [1209528000000, 2287726.0],
            [1212206400000, 2287726.0],
            [1214798400000, 2732646.0],
            [1217476800000, 2732646.0],
            [1220155200000, 2732646.0],
            [1222747200000, 2599196.0],
            [1225425600000, 2599196.0],
            [1228021200000, 2599196.0],
            [1230699600000, 1924387.0],
            [1233378000000, 1924387.0],
            [1235797200000, 1924387.0],
            [1238472000000, 1756311.0],
            [1241064000000, 1756311.0],
            [1243742400000, 1756311.0],
            [1246334400000, 1743470.0],
            [1249012800000, 1743470.0],
            [1251691200000, 1743470.0],
            [1254283200000, 1519010.0],
            [1256961600000, 1519010.0],
            [1259557200000, 1519010.0],
            [1262235600000, 1591444.0],
            [1264914000000, 1591444.0],
            [1267333200000, 1591444.0],
            [1270008000000, 1543784.0],
            [1272600000000, 1543784.0],
            [1275278400000, 1543784.0],
            [1277870400000, 1309915.0],
            [1280548800000, 1309915.0],
            [1283227200000, 1309915.0],
            [1285819200000, 1331875.0],
            [1288497600000, 1331875.0],
            [1291093200000, 1331875.0],
            [1293771600000, 1331875.0],
            [1296450000000, 1154695.0],
            [1298869200000, 1154695.0],
            [1301544000000, 1194025.0],
            [1304136000000, 1194025.0],
            [1306814400000, 1194025.0],
            [1309406400000, 1194025.0],
            [1312084800000, 1194025.0],
            [1314763200000, 1244525.0],
            [1317355200000, 475000.0],
            [1320033600000, 475000.0],
            [1322629200000, 475000.0],
            [1325307600000, 690033.0],
            [1327986000000, 690033.0],
            [1330491600000, 690033.0],
            [1333166400000, 514733.0],
            [1335758400000, 514733.0],
          ],
        },
      ];
    }
  );
