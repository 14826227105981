angular
  .module("kitstajm")
  .controller(
    "LoginCtrl",
    function ($scope, $location, Login, Auth, Slack, $window, CONSTANTS) {
      $scope.company = CONSTANTS.company;
      $scope.user = {};
      $scope.errorMessage = "";

      $scope.go = function (path) {
        $window.location = path;
      };

      $scope.slackPost = function (name) {
        var payload = {
          text: "Tack för att just du: " + name + ", loggar på kitstajmBeta!",
          channel: "@" + "patrik.nilsson",
        };
        Slack.send(
          payload,
          function (result) {
            //console.log('slack sent');
          },
          function (error) {
            //console.log('slack not sent', error);
          }
        );
      };

      $scope.handleLogin = function (user) {
        if (user.name && user.pwd) {
          Login.promise = Auth.check(
            {},
            { username: user.name, password: user.pwd },
            function (success) {
              //console.log(success);
              $scope.errorMessage = "";
              Login.IsLoggedIn(user.name, success);
              //$scope.slackPost(success.slack);
              $location.path("/time");
            },
            function (error) {
              $scope.errorMessage = error.statusText;
              //console.log(error.statusText);
            }
          ).$promise;
        }
      };
    }
  );
