import angular from "angular";
import uiRouter from "angular-ui-router";
import ngResource from "angular-resource";
import ngAnimate from "angular-animate";
import ngTouch from "angular-touch";
import ngCookies from "angular-cookies";
import ngSanitize from "angular-sanitize";
import uibootstrap from "angular-ui-bootstrap";
import uitree from "angular-ui-tree";
import angularLoadingBar from "angular-loading-bar";

import "nvd3";
import "angular-nvd3";
import ngCsv from "ng-csv";
import "angular-ui-grid/ui-grid.css";
import uiGrid from "angular-ui-grid";
import "angularjs-dropdown-multiselect";

import moment from "moment";
import sv from "moment/locale/sv";
import { utc } from "moment";
moment.locale("sv");

import fileSaver from "file-saver";

import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../node_modules/nvd3/build/nv.d3.css";
import "../../node_modules/angular-loading-bar/build/loading-bar.css";
import "../../node_modules/angular-ui-tree/dist/angular-ui-tree.css";

import "../style/app.css";
import "../style/app.less";

const MODULE_NAME = "kitstajm";
export default MODULE_NAME;

import { constants } from "../../globals.js";
angular.module("config", []).constant("CONSTANTS", constants);

import Config from "./Config";
import Runners from "./Runners";
//import Controllers         from './modules/Starter/Controllers';

const deps = [
  uiRouter,
  ngResource,
  ngAnimate,
  ngTouch,
  ngCookies,
  ngSanitize,
  uibootstrap,
  uitree,
  "nvd3",
  angularLoadingBar,
  ngCsv,
  uiGrid,
  "angularjs-dropdown-multiselect",
]; /** All global dependencies */
const modules = ["config"]; /** All app dependencies */

const myApp = angular
  .module(MODULE_NAME, deps.concat(modules))
  .config(Config)
  .run(Runners)
  .factory("moment", () => moment)
  .factory("fileSaver", () => fileSaver)
  .factory("httpInterceptor", [
    "$q",
    "$injector",
    "$location",
    function httpInterceptor($q, $injector, $location) {
      return {
        request: function (config) {
          //console.log(config);
          return config;
        },
        /*
                     'requestError': function(rejection) {
                     return $q.reject(rejection);
                     },     
                     'response': function(response) {
                     return response;
                     },
                     */
        responseError: function (rejection) {
          if (rejection.status === 401) {
            $injector.get("Login").IsLoggedOut();
            $location.path("/login");
          }
          return $q.reject(rejection);
        },
      };
    },
  ])
  .directive("selectOnClick", function () {
    return {
      restrict: "A",
      link: function (scope, element) {
        element.on("click", function () {
          this.select();
        });
      },
    };
  });
/** Declare root module */

require("./partial/about/about.js");
require("./partial/time/time.js");
require("./partial/login/login.js");
require("./partial/logout/logout.js");
require("./partial/invoice/invoice.js");
require("./partial/reports/reports.js");
require("./partial/salary/salary.js");
require("./partial/salesReports/salesReports.js");
require("./partial/kcReports/kcReports.js");
require("./partial/projectReport/projectReport.js");
require("./partial/projectSummaryReport/projectSummaryReport.js");
require("./partial/timeStatisticsReport/timeStatisticsReport.js");
require("./partial/invoiceReports/invoiceReports.js");
require("./partial/password/password.js");
require("./partial/kpi/kpi.js");

require("./partial/loginCallback/loginCallback.js");
require("./partial/admin/admin.js");
require("./partial/admin/customer/customer.js");
require("./partial/admin/project/project.js");
require("./partial/admin/activity/activity.js");
require("./partial/admin/employee/employee.js");

require("./service/invoicePdf.js");
require("./service/timeTravelMoment.js");
require("./service/Auth.js");
require("./service/Slack.js");
require("./service/Login.js");
require("./service/User.js");
require("./service/restServices.js");
require("./service/Trello.js");
require("./service/Modal.js");

require("./directive/header/header.js");
require("./directive/myDatePicker/myDatePicker.js");
