angular
  .module("kitstajm")
  .controller(
    "ReportsCtrl",
    function ($scope, Report, timeTravelMoment, KcList, moment, Login) {
      $scope.fte = [];

      $scope.xFunction = function () {
        return function (d) {
          return d.key;
        };
      };
      $scope.yFunction = function () {
        return function (d) {
          return d.y;
        };
      };
      $scope.zFunction = function () {
        return function (d) {
          return d.z;
        };
      };

      $scope.ooptions = {
        chart: {
          type: "pieChart",
          x: $scope.xFunction(),
          y: $scope.yFunction(),
          height: 300,
          showLabels: true,
          transitionDuration: 500,
          labelThreshold: 0.01,
          legend: {
            margin: {
              top: 5,
              right: 35,
              bottom: 5,
              left: 0,
            },
          },
        },
        title: {
          enable: true,
          text: "Timmar",
        },
      };

      $scope.formData = {};
      $scope.formData.dateTo = null;
      $scope.formData.dateFrom = null;
      $scope.opened1 = false;
      $scope.opened2 = false;

      //Datepicker
      $scope.dateOptions = {
        "year-format": "'yy'",
        "show-weeks": true,
        "starting-day": 1,
      };

      $scope.daFormat = "yyyy Vww";

      $scope.message = "Rapport";

      $scope.header = ["Konsult", "Kund", "Projekt", "Aktivitet", "Timmar"];

      $scope.custMode = { external: true, internal: true };
      $scope.groupMode = "A";
      $scope.timeMode = "A";
      $scope.sumMode = "A";

      if (!$scope.formData.dateFrom) {
        if (moment().date() > 15) {
          $scope.momentStart = moment(
            moment().startOf("day").startOf("month").format("YYYY-MM-DD")
          );
        } else {
          $scope.momentStart = moment(
            moment()
              .startOf("day")
              .startOf("month")
              .add(-1, "month")
              .format("YYYY-MM-DD")
          );
        }
        $scope.formData.dateFrom = $scope.momentStart.toDate();
      }

      if (!$scope.formData.dateTo) {
        $scope.momentStop = moment($scope.momentStart)
          .add(1, "month")
          .subtract(1, "days");
        $scope.formData.dateTo = $scope.momentStop.toDate();
      }

      $scope.getHeader = function () {
        return $scope.header;
      };

      $scope.koko = function () {
        //console.log($scope.invoice);
        return $scope.exportData;
      };

      Login.promise
        .then(() => {
          $scope.getSubordinate = (function () {
            var isChef = Login.isKc() || Login.isAdmin();
            isChef &&
              KcList.get(
                {},
                function (data) {
                  $scope.Subordinate = data;
                  return data;
                },
                function (error) {
                  return null;
                }
              );
          })();
        })
        .catch(() => {
          console.log("kan det stämma");
        });

      $scope.emp = {
        data: [
          { id: 1, name: "koko" },
          { id: 2, name: "kaka" },
        ],
      };

      $scope.myDropdownOptions = [
        {
          id: "S",
          label: "Standard",
        },
        {
          id: "I",
          label: "Intermediate",
        },
        {
          id: "B",
          label: "Best available",
        },
      ];
      $scope.myDropdownModel = [$scope.myDropdownOptions[0]];

      $scope.myDropdownSettings = {
        styleActive: true,
        checkBoxes: true,
        alwaysOpened: true,
        displayProp: "name",
        smartButtonMaxItems: 10,
      };

      $scope.updatePeriod = function (dir) {
        $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
        $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
        if (dir === -1) {
          $scope.momentStart.startOf("month").subtract(1, "months");
          $scope.momentStop.startOf("month").subtract(1, "days");
        } else {
          $scope.momentStart.startOf("month").add(1, "months");
          $scope.momentStop
            .startOf("month")
            .add(2, "months")
            .subtract(1, "days");
        }
        $scope.formData.dateFrom = $scope.momentStart.toDate();
        $scope.formData.dateTo = $scope.momentStop.toDate();
      };

      $scope.kaka = function (head, inv, index, row) {
        //console.log(index, row);
        if (!$scope.exportData[row]) {
          $scope.exportData[row] = [];
        }
        switch (head) {
          case "Konsult":
            $scope.exportData[row][index] = inv.name;
            break;
          case "Kund":
            $scope.exportData[row][index] = inv.customername;
            break;
          case "Projekt":
            $scope.exportData[row][index] = inv.project_name;
            break;
          case "Aktivitet":
            $scope.exportData[row][index] = inv.activity_name;
            break;
          case "Timmar":
            $scope.exportData[row][index] = inv.sum.toLocaleString("sv-SE", {
              useGrouping: false,
            });
            break;
          case "Datum":
            $scope.exportData[row][index] = moment(inv.reporteddate).format(
              "YYYY-MM-DD"
            );
            break;
          default:
            return;
        }
        return $scope.exportData[row][index];
      };

      $scope.kallejonka = function (from, to) {
        $scope.momentStart = moment($scope.formData.dateFrom);
        $scope.momentStop = moment($scope.formData.dateTo);

        var workHours = 0;
        var periods = {};

        for (
          var day = moment($scope.momentStart);
          day <= $scope.momentStop;
          day.add(1, "days")
        ) {
          if (timeTravelMoment.isRedDayMoment(day) !== undefined) {
            //console.log('röd dag ',day);
            continue;
          }
          if (timeTravelMoment.isWeekendDay(day)) {
            //console.log('helg dag ',day);
            continue;
          }
          //console.log('arbetsdag ',day);
          if (periods[day.month() + 1] === undefined) {
            periods[day.month() + 1] = 0;
          }
          periods[day.month() + 1] += 8;
          workHours += 8;
        }
        //console.log(workHours);
        $scope.periodHours = periods;
      };

      $scope.fetchInvoice = function () {
        $scope.invoice = [];

        $scope.exportData = [];

        switch ($scope.groupMode) {
          case "A":
            $scope.header = ["Kund", "Projekt", "Aktivitet", "Timmar"];
            break;

          case "P":
            $scope.header = ["Kund", "Projekt", "Timmar"];
            break;

          case "KU":
            $scope.header = ["Kund", "Timmar"];
            break;

          default:
            break;
        }

        switch ($scope.details) {
          case true:
            $scope.header.unshift("Datum");
            break;
          default:
            break;
        }
        $scope.header.unshift("Konsult");

        $scope.momentStart = moment($scope.formData.dateFrom);
        $scope.momentStop = moment($scope.formData.dateTo);

        let ids = [];
        for (let index = 0; index < $scope.fte.length; index++) {
          const element = $scope.fte[index];
          ids[index] = element.employee_id;
        }

        Report.get(
          {
            id: ids,
            subTotal: $scope.subTotal,
            details: $scope.details,
            sum: $scope.sumMode,
            custMode: $scope.custMode,
            per: $scope.timeMode,
            type: $scope.groupMode,
            from: $scope.momentStart.format("YYYY-MM-DD"),
            to: $scope.momentStop.format("YYYY-MM-DD"),
          },
          function (data) {
            $scope.invoice = data;
            $scope.salesReport = [];
            for (var j = 0; j < $scope.invoice.length; j++) {
              try {
                $scope.invoice[j].timpris =
                  Math.round(
                    ($scope.invoice[j].pris / $scope.invoice[j].sum) * 100
                  ) / 100;
                $scope.invoice[j].timprisNum = $scope.invoice[j].timpris;
                $scope.invoice[j].timpris = $scope.invoice[
                  j
                ].timpris.toLocaleString("sv-SE", { useGrouping: false });
              } catch (error) {
                $scope.invoice[j].timpris = $scope.invoice[j].timprisNum = null;
              }
              try {
                $scope.invoice[j].sumNum = $scope.invoice[j].sum;
                $scope.invoice[j].sum = $scope.invoice[j].sum.toLocaleString(
                  "sv-SE",
                  { useGrouping: false }
                );
              } catch (error) {
                $scope.invoice[j].sum = $scope.invoice[j].sumNum = null;
              }
              try {
                $scope.invoice[j].prisNum = $scope.invoice[j].pris;
                $scope.invoice[j].pris = $scope.invoice[j].pris.toLocaleString(
                  "sv-SE",
                  { useGrouping: false }
                );
              } catch (error) {
                $scope.invoice[j].pris =
                  $scope.invoice[j].prisNum =
                  $scope.invoice[j].pris =
                    null;
              }
              if (!$scope.invoice[j].pris) {
                $scope.invoice[j].timpris = null;
              }
              if ($scope.invoice[j].customername === null) {
                $scope.invoice[j].customername = "Tot";
              } else if (
                typeof $scope.invoice[j].project_name === "undefined"
              ) {
                $scope.invoice[j].project_name = null;
              } else if (
                typeof $scope.invoice[j].activity_name === "undefined"
              ) {
                $scope.invoice[j].activity_name = null;
              }

              switch ($scope.groupMode) {
                case "KU":
                  if ($scope.invoice[j].customername !== "Tot") {
                    $scope.salesReport.push({
                      key: $scope.invoice[j].customername,
                      y: $scope.invoice[j].sumNum,
                      z: $scope.invoice[j].prisNum,
                    });
                  }
                  break;
                case "P":
                  if ($scope.invoice[j].project_name) {
                    $scope.salesReport.push({
                      key: $scope.invoice[j].project_name,
                      y: $scope.invoice[j].sumNum,
                      z: $scope.invoice[j].prisNum,
                    });
                  }
                  break;
                case "A":
                  if ($scope.invoice[j].activity_name) {
                    $scope.salesReport.push({
                      key: $scope.invoice[j].activity_name,
                      y: $scope.invoice[j].sumNum,
                      z: $scope.invoice[j].prisNum,
                    });
                  }
                  break;
              }
            }
            //console.log($scope.invoice);
          }
        );
      };
    }
  );
