import datepicker from "./myDatePicker.html";
import datepickerweek from "./myDatePickerWeek.html";

angular
  .module("kitstajm")
  .directive("myDatePicker", function () {
    return {
      restrict: "E",
      scope: {
        ngModel: "=",
        dateOptions: "=",
        opened: "=",
        tajm: "=",
        daFormat: "=",
      },
      link: function ($scope, element, attrs) {
        $scope.open = function (event) {
          event.preventDefault();
          event.stopPropagation();
          $scope.opened = !$scope.opened;
        };

        $scope.clear = function () {
          $scope.ngModel = null;
        };
      },
      template: datepicker,
    };
  })
  .directive("myDatePickerWeek", function () {
    return {
      restrict: "E",
      scope: {
        ngModel: "=",
        dateOptions: "=",
        opened: "=",
        tajm: "=",
        daFormat: "=",
      },
      link: function ($scope, element, attrs) {
        $scope.open = function (event) {
          event.preventDefault();
          event.stopPropagation();
          $scope.opened = !$scope.opened;
        };

        $scope.clear = function () {
          $scope.ngModel = null;
        };
      },
      template: datepickerweek,
    };
  });
