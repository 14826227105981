angular
  .module("kitstajm")
  .factory("Projectss", function ($resource) {
    return $resource(
      "api/projectss/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );
  })
  .controller("ProjectCtrl", function ($scope, Projectss) {
    $scope.saveProj = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (typeof arr.editProj.project_id !== "undefined") {
        var tmpCustomer = arr.editProj.customer;
        arr.editProj.customer = null;

        Projectss.update(
          { id: arr.editProj.project_id },
          arr.editProj,
          function (result) {
            // Save back to array obj
            arr.editProj.customer = tmpCustomer;
            $scope.projCopy(arr.editProj, arr.selProj);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      } else {
        Projectss.insert(
          {},
          arr.editProj,
          function (result) {
            // Save back to array obj
            arr.isNew = false;
            // id from DB
            arr.editProj.project_id = result.insertId;
            // add to all projects
            var x = $scope.projects.data.push(arr.editProj) - 1;
            $scope.projects.selProj = $scope.projects.data[x];

            // add to selected customer projects list
            if (typeof arr.projects === "undefined") {
              arr.projects = [];
            }
            var y = arr.projects.push($scope.projects.selProj) - 1;
            arr.projIndex = y;

            arr.selProj = arr.projects[y];
            // remove ref to new
            arr.editProj = {};
            // copy back data
            $scope.projCopy(arr.selProj, arr.editProj);

            //$scope.projCopy(arr.editProj, arr.selProj);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      }
    };

    $scope.moveProj = function (dir, arr) {
      if (typeof arr === "undefined") {
        return;
      }

      if (dir >= 1 && arr.projIndex < arr.projects.length - 1) {
        arr.selProj = arr.projects[++arr.projIndex];
        $scope.projCopy(arr.selProj, arr.editProj);
      } else if (dir < 1 && arr.projIndex > 0) {
        arr.selProj = arr.projects[--arr.projIndex];
        $scope.projCopy(arr.selProj, arr.editProj);
      }
    };

    $scope.cancelProj = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (arr.isNew) {
        arr.isNew = false;
        arr.selProj = arr.prevSel;
      }

      delete arr.editProj;
      arr.editProj = {};
      $scope.projCopy(arr.selProj, arr.editProj);
    };

    $scope.addProj = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (!arr.isNew) {
        //$scope.projects.selProj = null;
        arr.prevSel = arr.selProj;
        arr.editProj = {};
        arr.selProj = arr.editProj;
        arr.editProj.customer_id = arr.customer_id;
        arr.isNew = true;
      }
    };
  });
