import kitsHeader from "./header.html";
import kitsHeaderMenu from "./headerMenu.html";
angular
  .module("kitstajm")
  .directive("kitsHeader", function () {
    return {
      restrict: "AE",
      replace: "true",
      template: kitsHeader,
    };
  })
  .directive("kitsHeaderMenu", function () {
    return {
      restrict: "AE",
      replace: "true",
      template: kitsHeaderMenu,
    };
  })
  .controller("HeaderCtrl", function ($scope, Login, Auth, $location) {
    $scope.message = "MoMayoy tothohe FoFororcoce bobe wowitothoh you";
    $scope.userLoggedIn = Login.getLoginState();
    $scope.browser = navigator.userAgent;

    $scope.isNavCollapsed = true;
    $scope.isCollapsed = false;
    $scope.isCollapsedHorizontal = false;

    $scope.userName = Login;

    $scope.handleClick = function (str) {
      $scope.isCollapsed = false;
      $location.path(str);
    };

    $scope.isAdmin = function () {
      return Login.isAdmin();
    };
    $scope.isSales = function () {
      return Login.isSales();
    };
    $scope.isBoard = function () {
      return Login.isBoard();
    };
    $scope.isKc = function () {
      return Login.isKc();
    };

    var colors = new Array(
      [62, 35, 255],
      [60, 255, 60],
      [255, 35, 98],
      [45, 175, 230],
      [255, 0, 255],
      [255, 128, 0]
    );

    var step = 0;
    //color table indices for:
    // current color left
    // next color left
    // current color right
    // next color right
    var colorIndices = [0, 1, 2, 3];
    //transition speed
    var gradientSpeed = 0.002;

    function updateGradient() {
      var c0_0 = colors[colorIndices[0]];
      var c0_1 = colors[colorIndices[1]];
      var c1_0 = colors[colorIndices[2]];
      var c1_1 = colors[colorIndices[3]];

      var istep = 1 - step;
      var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
      var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
      var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
      var color1 = "#" + ((r1 << 16) | (g1 << 8) | b1).toString(16);

      var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
      var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
      var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
      var color2 = "#" + ((r2 << 16) | (g2 << 8) | b2).toString(16);

      $("#gradientd")
        .css({
          background:
            "-webkit-gradient(linear, left top, right top, from(" +
            color1 +
            "), to(" +
            color2 +
            "))",
        })
        .css({
          background:
            "-moz-linear-gradient(left, " +
            color1 +
            " 0%, " +
            color2 +
            " 100%)",
        });

      step += gradientSpeed;
      if (step >= 1) {
        step %= 1;
        colorIndices[0] = colorIndices[1];
        colorIndices[2] = colorIndices[3];

        //pick two new target color indices
        //do not pick the same as the current one
        colorIndices[1] =
          (colorIndices[1] +
            Math.floor(1 + Math.random() * (colors.length - 1))) %
          colors.length;
        colorIndices[3] =
          (colorIndices[3] +
            Math.floor(1 + Math.random() * (colors.length - 1))) %
          colors.length;
      }
    }

    var timer = null;

    $scope.startUpdateGradient = function () {
      timer = setInterval(updateGradient, 100);
      //console.log('startgrad', timer);
    };

    $scope.stopUpdateGradient = function () {
      clearInterval(timer);
      timer = null;
      //console.log('stopgrad', timer);
    };

    $scope.toggleUpdateGradient = function () {
      if (timer === null) {
        $scope.startUpdateGradient();
      } else {
        $scope.stopUpdateGradient();
      }
    };

    //$scope.toggleUpdateGradient();
  });
