angular
  .module("kitstajm")
  .factory("Customers", function ($resource) {
    return $resource(
      "api/customers/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );
  })
  .controller("CustomerCtrl", function ($scope, Customers) {
    $scope.saveCust = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (typeof arr.editCust.customer_id !== "undefined") {
        Customers.update(
          { id: arr.editCust.customer_id },
          arr.editCust,
          function (result) {
            // Save back to array obj
            $scope.custCopy(arr.editCust, arr.selCust);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      } else {
        Customers.insert(
          {},
          arr.editCust,
          function (result) {
            // Save back to array obj
            arr.isNew = false;
            arr.editCust.customer_id = result.insertId;
            var x = $scope.customers.data.push(arr.editCust) - 1;
            arr.selCust = arr.editCust;
            arr.editCust = {};
            $scope.custCopy(arr.selCust, arr.editCust);
          },
          function (error) {
            //console.log('err update', error);
          }
        );
      }
    };

    $scope.moveCust = function (dir, arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (dir >= 1 && arr.index < arr.data.length - 1) {
        arr.selCust = arr.data[++arr.index];
        $scope.custCopy(arr.selCust, arr.editCust);
      } else if (dir < 1 && arr.index > 0) {
        arr.selCust = arr.data[--arr.index];
        $scope.custCopy(arr.selCust, arr.editCust);
      }
    };

    $scope.cancelCust = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (arr.isNew) {
        arr.isNew = false;
        arr.selCust = arr.prevSel;
      }
      delete arr.editCust;
      arr.editCust = {};
      $scope.custCopy(arr.selCust, arr.editCust);
    };

    $scope.addCust = function (arr) {
      if (typeof arr === "undefined") {
        return;
      }
      if (!arr.isNew) {
        //$scope.projects.selProj = null;
        arr.prevSel = arr.selCust;
        arr.editCust = {};
        arr.selCust = arr.editCust;
        arr.isNew = true;
      }
    };
  });
