angular
  .module("kitstajm")
  .controller("SalesreportsCtrl", function ($scope, SalesReports, moment) {
    $scope.xFunction = function () {
      return function (d) {
        return d.key;
      };
    };
    $scope.yFunction = function () {
      return function (d) {
        return d.y;
      };
    };
    $scope.zFunction = function () {
      return function (d) {
        return d.z;
      };
    };

    $scope.ooptions = {
      chart: {
        type: "pieChart",
        x: $scope.xFunction(),
        y: $scope.yFunction(),
        height: 300,
        showLabels: true,
        transitionDuration: 500,
        "labelThreshold:": 0.01,
        labelsOutside: true,
        legend: {
          margin: {
            top: 5,
            right: 35,
            bottom: 5,
            left: 0,
          },
        },
      },
      title: {
        enable: true,
        text: "Timmar",
      },
    };

    $scope.doptions = {
      chart: {
        type: "pieChart",
        x: $scope.xFunction(),
        y: $scope.zFunction(),
        height: 300,
        showLabels: true,
        transitionDuration: 500,
        labelThreshold: 0.01,
        labelsOutside: true,
        legend: {
          margin: {
            top: 5,
            right: 35,
            bottom: 5,
            left: 0,
          },
        },
      },
      title: {
        enable: true,
        text: "Omsättning",
      },
    };

    $scope.formData = {};
    $scope.formData.dateTo = null;
    $scope.formData.dateFrom = null;
    $scope.opened1 = false;
    $scope.opened2 = false;

    //Datepicker
    $scope.dateOptions = {
      "year-format": "'yy'",
      "show-weeks": true,
      "starting-day": 1,
    };

    $scope.message = "Rapport";

    $scope.header = [
      "Kund",
      "Projekt",
      "Aktivitet",
      "Namn",
      "Timmar",
      "Timpris",
      "Summa",
    ];

    $scope.custMode = { external: true, internal: false };
    $scope.groupMode = "P";
    $scope.timeMode = "A";
    $scope.sumMode = "A";

    if (!$scope.formData.dateFrom) {
      $scope.momentStart = moment(
        moment().startOf("day").startOf("month").format("YYYY-MM-DD")
      );
      $scope.formData.dateFrom = $scope.momentStart.toDate();
    }

    if (!$scope.formData.dateTo) {
      $scope.momentStop = moment($scope.momentStart)
        .add(1, "month")
        .subtract(1, "days");
      $scope.formData.dateTo = $scope.momentStop.toDate();
    }

    $scope.getHeader = function () {
      return $scope.header;
    };

    $scope.koko = function () {
      //console.log($scope.invoice);
      return $scope.exportData;
    };

    $scope.updatePeriod = function (dir) {
      $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
      $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
      if (dir === -1) {
        $scope.momentStart.startOf("month").subtract(1, "months");
        $scope.momentStop.startOf("month").subtract(1, "days");
      } else {
        $scope.momentStart.startOf("month").add(1, "months");
        $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
      }
      $scope.formData.dateFrom = $scope.momentStart.toDate();
      $scope.formData.dateTo = $scope.momentStop.toDate();
    };

    $scope.kaka = function (head, inv, index, row) {
      if (!$scope.exportData[row]) {
        $scope.exportData[row] = [];
      }
      switch (head) {
        case "Kund":
          $scope.exportData[row][index] = inv.customername;
          break;
        case "Projekt":
          $scope.exportData[row][index] = inv.project_name;
          break;
        case "Aktivitet":
          $scope.exportData[row][index] = inv.activity_name;
          break;
        case "Namn":
          $scope.exportData[row][index] = inv.name;
          break;
        case "Timmar":
          $scope.exportData[row][index] = inv.sum.toLocaleString("sv-SE", {
            useGrouping: false,
          });
          break;
        case "Timpris":
          $scope.exportData[row][index] = inv.timpris.toLocaleString("sv-SE", {
            useGrouping: false,
          });
          break;
        case "Summa":
          $scope.exportData[row][index] = inv.pris.toLocaleString("sv-SE", {
            useGrouping: false,
          });
          break;
        case "Bonus":
          $scope.exportData[row][index] = inv.bonus.toLocaleString("sv-SE", {
            useGrouping: false,
          });
          break;
        case "Ej bonus":
          $scope.exportData[row][index] = inv.ejbonus.toLocaleString("sv-SE", {
            useGrouping: false,
          });
          break;
        case "Bel":
          $scope.exportData[row][index] = (
            (0 + inv.ejbonus) / (0 + inv.sum) +
            ""
          ).toLocaleString("sv-SE", { useGrouping: false });
          break;
      }
      return $scope.exportData[row][index];
    };

    $scope.fetchInvoice = function () {
      $scope.exportData = [];
      $scope.invoice = [];

      switch ($scope.groupMode) {
        case "K":
          $scope.header = [
            "Kund",
            "Projekt",
            "Aktivitet",
            "Namn",
            "Timmar",
            "Timpris",
            "Summa",
          ];
          break;
        case "A":
          $scope.header = [
            "Kund",
            "Projekt",
            "Aktivitet",
            "Timmar",
            "Timpris",
            "Summa",
          ];
          break;

        case "P":
          $scope.header = ["Kund", "Projekt", "Timmar", "Timpris", "Summa"];
          break;

        case "KU":
          $scope.header = ["Kund", "Timmar", "Timpris", "Summa"];
          break;

        default:
          break;
      }

      $scope.momentStart = moment($scope.formData.dateFrom);
      $scope.momentStop = moment($scope.formData.dateTo);

      SalesReports.get(
        {
          subTotal: $scope.subTotal,
          sum: $scope.sumMode,
          custMode: $scope.custMode,
          per: $scope.timeMode,
          type: $scope.groupMode,
          from: $scope.momentStart.format("YYYY-MM-DD"),
          to: $scope.momentStop.format("YYYY-MM-DD"),
        },
        function (data) {
          $scope.invoice = data;
          var reportIndex;
          var totSum = 0;
          $scope.salesReport = [];
          for (var j = 0; j < $scope.invoice.length; j++) {
            try {
              $scope.invoice[j].timpris =
                Math.round(
                  ($scope.invoice[j].pris / $scope.invoice[j].sum) * 100
                ) / 100;
              $scope.invoice[j].timprisNum = $scope.invoice[j].timpris;
              $scope.invoice[j].timpris = $scope.invoice[
                j
              ].timpris.toLocaleString("sv-SE", { useGrouping: false });
            } catch (error) {
              $scope.invoice[j].timpris = $scope.invoice[j].timprisNum = null;
            }
            try {
              $scope.invoice[j].sumNum = $scope.invoice[j].sum;
              $scope.invoice[j].sum = $scope.invoice[j].sum.toLocaleString(
                "sv-SE",
                { useGrouping: false }
              );
            } catch (error) {
              $scope.invoice[j].sum = $scope.invoice[j].sumNum = null;
            }
            try {
              $scope.invoice[j].prisNum = $scope.invoice[j].pris;
              $scope.invoice[j].pris = $scope.invoice[j].pris.toLocaleString(
                "sv-SE",
                { useGrouping: false }
              );
            } catch (error) {
              $scope.invoice[j].pris = null;
            }
            if (!$scope.invoice[j].pris) {
              $scope.invoice[j].timpris = null;
            }
            if ($scope.invoice[j].customername === null) {
              $scope.invoice[j].customername = "Tot";
            } else if (typeof $scope.invoice[j].project_name === "undefined") {
              $scope.invoice[j].project_name = null;
            } else if (typeof $scope.invoice[j].activity_name === "undefined") {
              $scope.invoice[j].activity_name = null;
            }

            switch ($scope.groupMode) {
              case "KU":
                if ($scope.invoice[j].customername !== "Tot") {
                  $scope.salesReport.push({
                    key: $scope.invoice[j].customername,
                    y: $scope.invoice[j].sumNum,
                    z: $scope.invoice[j].prisNum,
                  });
                }
                break;
              case "P":
                if ($scope.invoice[j].project_name) {
                  $scope.salesReport.push({
                    key: $scope.invoice[j].project_name,
                    y: $scope.invoice[j].sumNum,
                    z: $scope.invoice[j].prisNum,
                  });
                }
                break;
              case "A":
                if ($scope.invoice[j].activity_name) {
                  $scope.salesReport.push({
                    key: $scope.invoice[j].activity_name,
                    y: $scope.invoice[j].sumNum,
                    z: $scope.invoice[j].prisNum,
                  });
                }
                break;
            }
          }
          //console.log($scope.salesReport);
        }
      );
    };
  });
