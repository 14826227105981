angular
  .module("kitstajm")
  .factory("Auth", function ($resource) {
    return $resource(
      "api/login",
      {},
      {
        get: { method: "GET" },
        check: { method: "POST" },
        changePwd: { method: "PUT" },
      }
    );
  })
  .factory("Auth2", [
    "$resource",
    function ($resource) {
      return $resource(
        "api/logout",
        {},
        {
          check: { method: "GET" },
        }
      );
    },
  ]);
